<template>
  <index-content>
    <template #options>
      <n-refresh-info :response="response" />
    </template>
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <colgroup>
          <col class="n-table-trading-date" />
          <col class="n-table-timestamp" />
          <col class="n-table-timestamp" />
          <col class="login" />
          <col class="trading" />
          <col class="pricing" />
          <col class="transfer" />
          <col v-table-action />
        </colgroup>
        <thead>
          <tr>
            <th rowspan="2">{{ $t('label.tradingDate') }}</th>
            <th rowspan="2">{{ $t('label.sodTime') }}</th>
            <th rowspan="2">{{ $t('label.eodTime') }}</th>
            <th colspan="4">{{ $t('label.restriction') }}</th>
            <th rowspan="2">{{ $t('label.action') }}</th>
          </tr>
          <tr>
            <th class="login">{{ $t('label.login') }}</th>
            <th class="trading">{{ $t('label.trading') }}</th>
            <th class="pricing">{{ $t('label.editing') }}</th>
            <th class="transfer">{{ $t('label.transfer') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ record.tradingDate }}</td>
            <td>{{ record.sodTime | datetime }}</td>
            <td>{{ record.eodTime | datetime }}</td>
            <td v-enums:LoginRestriction="record.loginRestriction" class="n-ellipsis"></td>
            <td v-enums:TradingRestriction="record.tradingRestriction" class="n-ellipsis"></td>
            <td v-enums:EditingRestriction="record.editingRestriction" class="n-ellipsis"></td>
            <td v-enums:TransferRestriction="record.transferRestriction" class="n-ellipsis"></td>
            <td class="n-button-box">
              <nb-custom text="holidays" @on-click="toHoliday(record)"></nb-custom>
              <nb-custom text="sessions" @on-click="toSession(record)"></nb-custom>
              <nb-modal @on-click="onBtnShowModal(record)"></nb-modal>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import BaseMarketView from './view';
import ModalMarket from './ModalMarket';
import { fetch, refresh } from '@/api/market/market-market';

export default BaseMarketView.extend({
  name: 'MarketMarket',

  data() {
    return {
      preload: true,
    };
  },

  methods: {
    doLoad(refreshable) {
      return refreshable ? refresh() : fetch();
    },

    parse(r) {
      this.record = r;
    },

    toHoliday(o) {
      this.pushMenu({ url: '/market/market/holidays' });
    },

    toSession(o) {
      this.pushMenu({ url: '/market/market/sessions' });
    },

    onBtnShowModal(market) {
      this.createModal(ModalMarket, { model: market, on: this });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  col.rw-action {
    width: 14.5rem;
  }

  col.ro-action {
    width: 15rem;
  }
}
</style>
