<template>
  <n-modal v-bind="$props">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <!-- sodTime -->
        <!-- prettier-ignore -->
        <n-field-datetime v-model="model.sodTime" field-name="sodTime" label="sodTime" :error="error.sodTime" :disabled="isEdit || disabled" />
        <!-- prevSodTime -->
        <!-- prettier-ignore -->
        <n-field-datetime v-model="model.prevSodTime" field-name="prevSodTime" label="prevSodTime" :error="error.prevSodTime" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- eodTime -->
        <!-- prettier-ignore -->
        <n-field-datetime v-model="model.eodTime" field-name="eodTime" label="eodTime" :error="error.eodTime" :disabled="isEdit || disabled" />
        <!-- prevEodTime -->
        <!-- prettier-ignore -->
        <n-field-datetime v-model="model.prevEodTime" field-name="prevEodTime" label="prevEodTime" :error="error.prevEodTime" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- tradingDate -->
        <!-- prettier-ignore -->
        <n-field-date v-model="model.tradingDate" field-name="tradingDate" label="tradingDate" :error="error.tradingDate" :disabled="isEdit || disabled" />
        <!-- prevEodTime -->
        <!-- prettier-ignore -->
        <n-field-date v-model="model.prevTradingDate" field-name="prevTradingDate" label="prevTradingDate" :error="error.prevTradingDate" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- loginRestriction -->
        <!-- prettier-ignore -->
        <n-field-enum v-model="model.loginRestriction" field-name="loginRestriction" enum-name="LoginRestriction" :error="error.loginRestriction" label="loginRestriction" :disabled="disabled" />
        <!-- tradingRestriction -->
        <!-- prettier-ignore -->
        <n-field-enum v-model="model.tradingRestriction" field-name="tradingRestriction" enum-name="TradingRestriction" :error="error.tradingRestriction" label="tradingRestriction" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- editingRestriction -->
        <!-- prettier-ignore -->
        <n-field-enum v-model="model.editingRestriction" field-name="editingRestriction" enum-name="EditingRestriction" :error="error.editingRestriction" label="editingRestriction" :disabled="disabled" />
        <!-- transferRestriction -->
        <!-- prettier-ignore -->
        <n-field-enum v-model="model.transferRestriction" field-name="transferRestriction" enum-name="TransferRestriction" :error="error.transferRestriction" label="transferRestriction" :disabled="disabled" />
      </n-row-col2>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { validate, edit } from '@/api/market/market-market';

export default NModal.extend({
  name: 'ModalMarket',
  components: { NModal },

  methods: {
    doSubmit(model) {
      return edit(model);
    },

    doValidate(model) {
      return validate(model);
    },
  },
});
</script>
