import { $fetch, $post } from '@/services/axios';

export function validate(data) {
  return $post(`market/validate`, data);
}

export function edit(data) {
  return $post(`market`, data);
}

export function fetch() {
  return $fetch(`market`);
}

export function refresh() {
  return $fetch(`market/refresh`);
}
